import { Collapse, Divider, Paper, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import GitHubLoginButton from "./GitHubLoginButton";
import GitlabLoginButton from "./GitlabLoginButton";
import GoogleLoginButton from "./GoogleLoginButton";
import BibucketLoginButton from "./BibucketLoginButton";
import { alpha } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import blockedDomains from "../Assets/blockedDomains.json";
import { register } from '../actions/index';
import '../index.css';
import { CONFIG_APP_BASE_COMPANY } from '../configApp';


var CryptoJS = require("crypto-js");

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: "12px",
    width: '90%',
  },
  image: {
    backgroundImage: 'url(https://react-nife-2024.s3.ap-south-1.amazonaws.com/register.png)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '80%', // Fix IE 11 issue.
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  input: {
    borderRadius: "50%",
  },
  margin: {


  },
  gridbg: {
    // backgroundColor: '#fff',
    marginLeft: "0%",
  },
  error: {
    padding: "10px",
    paddingTop: "0px",
    color: "red",
    marginBottom: "0px",
  },
  hr: {
    margin: "10px",
    color: "#ffe",
    height: 0,
  },
  link: {
    textAlign: "center",
  },
  button: {
    width: "50%",
  },
  gridmargin: {
    marginBottom: "90px",
  },
  title: {
    color: "#160044",
  },
  subtitle: {
    color: "grey",
  },
  para: {
    fontWeght: "normal",
    margin: "0px",
    color: "grey",
  },
}));

const useStylesCustomizeTextField = makeStyles((theme) => ({
  root: {
    border: '1px solid #e2e2e1',
    overflow: 'hidden',
    borderRadius: 25,
    height: '48px',
    marginTop: '5px',
    marginBottom: '5px',
    backgroundColor: theme.palette.background.paper,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
    },
    '&$focused': {
      backgroundColor: theme.palette.background.paper,
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
  focused: {},
}));

function CustomizeTextField(props) {
  const classes = useStylesCustomizeTextField();

  return <TextField InputProps={{ classes, disableUnderline: true }} {...props} />;
}


function Register(props) {
  const isDarkTheme = useTheme().palette.mode === 'dark';
  const classes = useStyles();
  /*local state*/
  const [values, setValues] = useState({
    sessionId: '',
    firstName: '',
    lastName: '',
    emailId: '',
    password: '',
    companyName: '',
    phoneNumber: '',
    location: '',
    industry: '',
    //billingAddress: '',
    payment: '',
    cardDetails: '',
    cvv: '',
  })
  const [emailValidation, setEmailValidation] = useState(false)
  const [companyValidation] = useState(false)
  const [passwordValidation, setPasswordValidation] = useState(false)
  const [passwordErrors, setPasswordErrors] = useState('')
  const [isProgress, setIsProgress] = useState(false)
  const urlid = useQuery().get("id")
  const onChange = e => setValues({ ...values, [e.target.name]: e.target.value });


  const post_registraion = () => {
    setIsProgress(false)
  }
  const handleSubmit = (e) => {
    var temp;
    if (values.companyName) {
      temp = values.companyName
    }
    else {
      temp = values.emailId.split('@')[0].replace(".", "-")
    }

    setIsProgress(true)
    e.preventDefault();
    const data = {
      "sessionId": urlid,
      "email": values.emailId,
      "password": encodeAes(values.password),
      "companyName": temp,
      "phoneNumber": encodeAes(values.phoneNumber),
      "industry": "Undefined",
      "location": values.location ? values.location : "Undefined",
      "firstName": "Nife",
      "lastName": "User",
    }
    props.register(data, post_registraion)
    localStorage.setItem("useronboard", JSON.stringify(data))

  }


  const [isDomainBlocked, setisDomainBlocked] = useState(false);

  const valuevalidateEmail = (e) => {
    setisDomainBlocked(blockedDomains.indexOf(e.target.value.toLowerCase().split("@")[1]) > -1)

    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const checkvalue = re.test(e.target.value.toLowerCase())
    if (checkvalue.length > 0) {
      setEmailValidation(false)
    }
    setEmailValidation((checkvalue) ? false : true)
  }



  const valuevalidatePassword = (e) => {
    const p = e.target.value
    const errors = [];
    if (p.length < 8) {
      errors.push("Your password must be at least 8 characters in length");
    }
    if (p.length > 60) {
      errors.push("Your password cannot be more than 60 characters in length");
    }
    if (p.search(/[a-z]/) < 0) {
      errors.push(" contains at least one lower case letter");
    }
    if (p.search(/[A-Z]/) < 0) {
      errors.push(" contains at least one upper case letter");
    }
    if (p.search(/[0-9]/) < 0) {
      errors.push(" at least one digit");
    }
    if (errors.length > 0) {
      setPasswordErrors(errors.join(","))
    }
    setPasswordValidation((errors.length > 0) ? true : false)
  }

  /*init*/
  useEffect(() => {
    setIsProgress(false)
    setValues({ ...values, sessionId: urlid });
    axios.get("https://api.ipify.org?format=json").then((res) => {
      axios.get(`https://ipapi.co/${res.data.ip}/json/`).then((res2) => {
        setValues({ ...values, phoneNumber: res2.data.country_calling_code, location: res2.data.city + ", " + res2.data.country_name })
      })

    })
  }, []);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  function encodeAes(plaintText) {
    var key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_AES_KEY);
    var encryptedData = CryptoJS.AES.encrypt(plaintText, key, {
      iv: key,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    encryptedData = encryptedData.ciphertext.toString();
    return encryptedData

  }

  const { regmsg } = props.authReducer
  // if (signedIn) {
  //   return <Redirect to='/verify-email' />
  // }

  if (urlid == null) {
    localStorage.setItem("sessionId", "")
  }
  else {
    localStorage.setItem("sessionId", urlid)
  }

  return (
    <Paper elevation={0}>
      <div className="Register">
        <Grid container component="main" className={classes.root}>
          <Grid item xs={null} sm={null} md={1} />
          <Grid item xs={12} sm={12} md={6} className={classes.gridbg}>
            <Grid wrap="wrap" container direction="column">
              {isDarkTheme ?
                <img alt="logo" loading='lazy' src={"/OikosDark.svg"} height="120px" width="140px" /> :

                <img alt="logo" loading='lazy' src={CONFIG_APP_BASE_COMPANY === "nife" ? "/Logo.svg" : "/Zeke.svg"} height="120px" width="140px" />}
              <Typography variant='h3' sx={{ fontWeight: "bold", fontFamily: "Rubik", fontSize: "2rem" }}>
                Lets Get Started!
              </Typography>
              <Typography sx={{ mt: 2 }} variant='body2'>
                Deploy workloads to distributed computing resources over multiple infrastructures, environments & geographies.
              </Typography>


            </Grid>
            <form className={classes.form} onSubmit={e => handleSubmit(e)}>
              {regmsg && <h5 className={classes.error}>{regmsg}</h5>}

              <Grid container >
                <Grid item xs={12} sm={12}>
                  <Typography variant='h6' sx={{ fontWeight: "bold", py: 2 }}>Registration</Typography>
                  <Box>
                    <Typography variant='bdoy2' sx={{ color: "grey", pb: 4 }}>Already have an account?
                      <Button component={Link} to="/" size='medium'
                        sx={{
                          fontSize: "0.975rem", py: 0, textTransform: "none", color: "grey", mb: 0,
                          '&:hover': {
                            color: theme => theme.palette.primary.main,  // Change text color to blue on hover
                            textDecoration: "underline", // Optional: Add underline on hover
                          },
                        }}  >
                        <u>Login here!</u>
                      </Button>
                      
                    </Typography>
                  </Box>

                </Grid>

                <Grid item xs={12} sm={12}>
                  <Collapse in={values.emailId === ""}>
                    <Box sx={{mt:2}}>

                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <GoogleLoginButton />

                        </Grid>
                        <Grid item xs={6}>
                          <GitHubLoginButton />

                        </Grid>
                        <Grid item xs={6}>
                          <GitlabLoginButton />

                        </Grid>
                        <Grid item xs={6}>
                          <BibucketLoginButton />
                        </Grid>
                      </Grid>
                      <Typography color={"primary"} sx={{ pt: 2,pb:1 }}>
                        <svg stroke="currentColor" fill="currentColor" style={{ marginRight: "5px" }} stroke-width="0" viewBox="0 0 256 256" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M212,224a12,12,0,0,1-12,12A108.12,108.12,0,0,1,92,128V61L64.49,88.49a12,12,0,0,1-17-17l48-48a12,12,0,0,1,17,0l48,48a12,12,0,0,1-17,17L116,61v67a84.09,84.09,0,0,0,84,84A12,12,0,0,1,212,224Z"></path></svg>
                        One click Registration or Continue with email..</Typography>
                    </Box>
                  </Collapse>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <CustomizeTextField
                    label="Email Address"
                    name="emailId"
                    className={classes.margin}
                    helperText={isDomainBlocked ? "This email's domain is blocked, please try another email " : emailValidation && "Invalid email"}
                    variant="filled"
                    id="text-input" required
                    value={values.emailId}
                    type={'email'}
                    error={isDomainBlocked ? true : emailValidation}

                    onChange={e => { onChange(e); valuevalidateEmail(e) }}

                  />

                </Grid>
                <Collapse in={values.emailId !== ""}>
                  <Grid item xs={12} sm={12}>
                    <CustomizeTextField
                      label="New Password"
                      name="password"
                      className={classes.margin}
                      helperText={passwordValidation && passwordErrors}
                      variant="filled"
                      id="text-input" required
                      type="password"
                      value={values.password}
                      onChange={e => { onChange(e); valuevalidatePassword(e) }}
                      error={passwordValidation}
                    />

                  </Grid>

                  <Grid item xs={12} sm={12}>

                    <Button variant='contained' fullWidth sx={{ borderRadius: "24px", height: 48, mb: 5, mt: 1 }} disableElevation disabled={companyValidation || passwordValidation || emailValidation || isDomainBlocked} type="submit" className={classes.button}>{isProgress ? <CircularProgress size={20} style={{ color: "white" }} /> : "Next"}</Button>
                    {/* <Button variant='contained' fullWidth sx={{ borderRadius: "24px", height: 48,mb:5}} disableElevation type="submit" className={classes.button}>{isProgress ? <CircularProgress size={20} style={{ color: "white" }} /> : "Next"}</Button> */}
               
                  </Grid>
                </Collapse>
                <Box sx={{mb:4}}>

                </Box>
             
              </Grid>
            </form>
          </Grid>
          <Grid component={Box} item xs={false} sm={false} md={5} className={classes.image} display={{ xs: "none", lg: "block" }} />
        </Grid>

      </div>
    </Paper>
  );
}


const mapStateToProps = ({ authReducer }) => {
  return { authReducer }
}
export default connect(mapStateToProps, { register })(Register)
